var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: [_vm.component.status],
      on: {
        click: function ($event) {
          return _vm.$emit("onSelection", _vm.component)
        },
      },
    },
    [
      _c("time-indicator", {
        staticClass: "time-indicator",
        attrs: { component: _vm.component },
      }),
      _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.component.name))]),
      _vm.component.status === _vm.COMPONENT_STATUS.STANDBY
        ? _c("span", { staticClass: "tag" }, [
            _vm._v(" " + _vm._s(_vm.component.status) + " "),
          ])
        : _vm._e(),
      _vm.component.ref
        ? _c("span", { staticClass: "ref" }, [
            _vm._v(_vm._s(_vm.component.ref)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }