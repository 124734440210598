var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: "component", staticClass: "selection" },
    [
      _c("h2", { staticClass: "title" }, [_vm._v("Choose component:")]),
      _c("hr"),
      _c(
        "div",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _vm.$emit("onBack")
            },
          },
        },
        [
          _c("svgicon", {
            staticClass: "triangle",
            attrs: { name: "triangle" },
          }),
          _vm._m(0),
          _c("span", [
            _vm._v(
              " " +
                _vm._s(_vm.selected_project.name) +
                " / Stage #" +
                _vm._s(_vm.selected_stage.number) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c("hr"),
      _vm.components
        ? _c(
            "ul",
            _vm._l(_vm.components, function (component) {
              return _c("component-selector-block", {
                key: component.id,
                attrs: { component: component },
                on: {
                  onSelection: function ($event) {
                    return _vm.$emit("onSelection", component)
                  },
                },
              })
            }),
            1
          )
        : _c("el-alert", {
            staticStyle: { "margin-top": "10px" },
            attrs: { description: "No components available", closable: false },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_vm._v("BACK "), _c("span", [_vm._v("/")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }