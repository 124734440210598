var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: "project", staticClass: "selection" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("h2", { staticClass: "title" }, [_vm._v("Choose project:")]),
        _c("el-input", {
          ref: "search",
          staticClass: "search-field",
          attrs: { placeholder: "Filter by name or ref", size: "mini" },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
      ],
      1
    ),
    _c("hr"),
    _c(
      "ul",
      { staticClass: "projects" },
      _vm._l(_vm.projects, function (project) {
        return _c(
          "li",
          {
            key: project.id,
            on: {
              click: function ($event) {
                return _vm.$emit("onSelection", project)
              },
            },
          },
          [
            _c("label", [
              _c("span", [_vm._v(_vm._s(project.ref))]),
              _vm._v(" " + _vm._s(project.name) + " "),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }