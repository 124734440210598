var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: "stage", staticClass: "selection" },
    [
      _c("h2", { staticClass: "title" }, [_vm._v("Choose stage:")]),
      _c("hr"),
      _c(
        "div",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _vm.$emit("onBack")
            },
          },
        },
        [
          _c("svgicon", {
            staticClass: "triangle",
            attrs: { name: "triangle" },
          }),
          _vm._m(0),
          _c("span", [_vm._v(_vm._s(_vm.selected_project.name))]),
        ],
        1
      ),
      _c("hr"),
      _vm.highlighted_components.length
        ? _c(
            "ul",
            { staticClass: "highlighted-components" },
            _vm._l(_vm.highlighted_components, function (component) {
              return _c("component-selector-block", {
                key: component.id,
                attrs: { component: component },
                on: {
                  onSelection: function ($event) {
                    return _vm.$emit("onSelection", component)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.stages
        ? _c(
            "ul",
            { staticClass: "stages" },
            _vm._l(_vm.stages, function (stage) {
              return _c(
                "li",
                {
                  key: stage.id,
                  staticClass: "stage",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("onSelection", stage)
                    },
                  },
                },
                [
                  _c("span", [_vm._v("Stage #" + _vm._s(stage.number))]),
                  stage.notes
                    ? _c("p", [_vm._v(_vm._s(stage.notes))])
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _c("el-alert", {
            staticStyle: { "margin-top": "10px" },
            attrs: { description: "No stages available", closable: false },
          }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [_vm._v("BACK "), _c("span", [_vm._v("/")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }